<template>
    <ThanhTieuDeVue :TieuDe="TieuDe"
        >
        <template #after>
            <div></div>
        </template>
    </ThanhTieuDeVue>
    <div class="container-md">
        <slot name="content" ref="Content"></slot>
    </div>
    <div class="container-xs">
        <ion-button @click="chinhSua" color="primary" expand="block">
            Xác nhận
        </ion-button>
    </div>
    <!-- ====================================Popup Thông báo ========================================= -->
    <PopupVue
        height="38%"
        :dialog="dialogThongBao"
        title=""
        :useButtonLeft="false"
        @close="
            () => {
                dialogThongBao = false;
            }
        "
    >
        <template #content
            ><ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import TaiKhoan from "../../../class/TaiKhoan";
import { IonButton } from "@ionic/vue";

export default {
    layout: "application",
    components: { ThanhTieuDeVue, PopupVue, ThongBaoVue, IonButton },
    props: {
        TieuDe: { type: String, default: "" },
        model: { type: [String, Number], default: "" },
        keyChinhSua: { type: String, default: "" },
    },
    data() {
        return {
            dialogThongBao: false,
            noiDung: null,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    watch: {
        model() {
            this.noiDung = this.model;
        },
    },
    methods: {
        async chinhSua() {
            // let valid = this.$scopedSlots
            //     .content()[0]
            //     .context.$refs.formValidation.instance.validate();
            // if (!valid.isValid) {
            //     return;
            // }
            if (this.keyChinhSua == "diaChi") {
                this.$emit("ChinhSua");
                return;
            }
            try {
                let rs = await new TaiKhoan().chinhSuaThongTinCaNhan(
                    this.keyChinhSua,
                    this.noiDung
                );
                if (!rs) {
                    throw new Error();
                }
                if (!rs.StatusCode === 200) {
                    return this.thongBao(
                        "Error",
                        "Lỗi kết nối đến máy chủ",
                        rs.Data.message
                    );
                }
                this.$router.back();
            } catch (error) {
                console.log(
                    "🚀 ~ file: chi-tiet.vue ~ line 163 ~ getData ~ error",
                    error
                );
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>